.shadow-2xl-custom {
  box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.4);
}

.shadow-xl-custom {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
}

.shadow-image-custom {
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.15);
}

.bg-cover-over-image {
  background: rgba(66, 153, 225, 0.9);
  border-radius: 0.5rem 0rem 0rem 0.5rem;
}

.text-8xl-custom {
  font-size: 88px;
}

.text-ellipsis-custom {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3; /* Number of lines to show */
}

.button-custom {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}/*# sourceMappingURL=App.css.map */